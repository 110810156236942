// Open all external links in new tab
const all_links = document.querySelectorAll('a');

for (var i = 0; i < all_links.length; i++) {
  var a = all_links[i];

  if (a.hostname != location.hostname) {
    a.rel = 'noopener';
    a.target = '_blank';
  }
}

const menuToggle = document.querySelector('#menuToggle');
const menu = document.querySelector('#openMenu');
const enterKeyCode = 13;
const spaceKeyCode = 32;
menu.addEventListener('change', () => {
  const menuOpen = menu.checked;
  menuToggle.setAttribute('aria-expanded', menuOpen);
});
menuToggle.addEventListener('keyup', event => {
  if (event.keyCode == enterKeyCode || event.keyCode == spaceKeyCode) {
    let menuOpen = menu.checked;

    if (menuOpen) {
      menu.checked = false;
    } else {
      menu.checked = true;
    }
  }
}); // Newsletter signup handling...

const newsletterSignupForm = document.querySelector('#newsletter');

if (newsletterSignupForm) {
  newsletterSignupForm.addEventListener('submit', async e => {
    e.preventDefault();
    const emailAddress = document.querySelector('#emailAddress').value;
    const output = document.querySelector('#output');

    try {
      // eslint-disable-next-line compat/compat
      const response = await fetch('/.netlify/functions/newsletter-signup', {
        method: 'post',
        body: JSON.stringify({
          email: emailAddress
        })
      });
      const data = await response.json();

      if (response.status === 200) {
        // Success
        output.innerHTML = 'Thank you for subscribing to our newsletter!';
        output.classList.add('success');
        output.style.maxHeight = '1.5em';
        console.log('success');
        newsletterSignupForm.reset();
      } else {
        // Error
        output.innerHTML = data.error;
        output.classList.add('error');
        output.style.maxHeight = '1.5em';
        console.error(data.error);
      }
    } catch (e) {
      console.error('error caught');
      console.log(e);
    }
  });
}